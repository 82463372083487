.logo {
    width: 100px;
}

.logo-sm {
    width: 85px;
}

.logo-lg {
    width: 150px;
}

.logoImg {
    display: block;
    width: 100%;
    height: 100%;
}
