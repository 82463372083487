.btn-outline-primary {
    border-color: rgb(var(--primary)) !important;
    color: rgb(var(--primary)) !important;
}

.btn-outline-primary:hover {
    color: #fff !important;
    background-color: rgb(var(--primary)) !important;
    border-color: rgb(var(--primary)) !important;
}

.btn-outline-primary:focus {
    box-shadow: 0 0 0 0.25rem rgba(var(--primary), 0.2) !important;
}

.btn-primary {
    background-color: rgb(var(--primary)) !important;
    border-color: rgb(var(--primary)) !important;
}

.btn-primary:hover {
    color: #fff !important;
    background-color: rgb(var(--primary)) !important;
    border-color: rgb(var(--primary)) !important;
    filter: brightness(95%) !important;
}

.btn-primary:focus {
    color: #fff !important;
    background-color: rgb(var(--primary)) !important;
    border-color: rgb(var(--primary)) !important;
    box-shadow: 0 0 0 0.25rem rgba(var(--primary), 0.2) !important;
}

a {
    color: rgb(var(--primary)) !important;
}