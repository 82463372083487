.container {
    margin-top: 10px;
}

.nav {
    z-index: 99;
}

.headerWrapper {
    position: relative;
    z-index: 99;
}

.headerWrapper * {
    direction: rtl;
    text-align: right;
}

.fixedHeaderWrapper {
    background-color: white;
    box-shadow: 0 1px 30px rgba(0, 0, 0, .2);
}

.header {
    background: #fff;
    padding: 30px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    width: 85%;
    margin: 0 auto;
    margin-bottom: 70px;
}

.headerIcon {
    background: #fff;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    text-align: center;
    line-height: 100px;
    vertical-align: middle;
    padding: 13px;
    box-shadow: 0 1px 30px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.headerHelpDropDown {
    display: inline-block;
}

.headerHelpDropDown button {
    background: transparent !important;
    border: unset !important;
    padding: 0 !important;
}

.headerHelpDropDown button:hover {
    background: transparent !important;
    border: unset !important;
    filter: unset;
}

.headerHelpDropDown button:focus {
    background-color: transparent !important;
    box-shadow: none !important;
}

.headerHelp {
    margin-right: 30px;
}

.headerHelpTitle {
    margin-right: 10px;
    font-weight: bold;
}

.header img {
    transform: scale(0.9);
}

.headerTitle {
    font-size: .8rem;
    font-weight: 500;
}

.headerDropdown:focus {
    border: 0;
    box-shadow: none;
}

.headerProfileDropdown {
    background-color: #fff;
}

.headerProfileDropdown::after {
    position: relative;
}

.headerProfileDropdown span {
    font-weight: 800;
    font-size: 0.8rem;
}

.headerLeft {
    width: 100%;
    max-width: 100%;
    display: flex;
    grid-gap: 20px;
    align-items: center;
    -ms-grid-column-align: center;
}

.headerLeftCol {
    display: flex;
    align-items: center;
}

.userDropdown button[aria-expanded="true"] svg * {
    fill: #fff !important;
}

/* -------------------------------RESPONSIVE --------------------------------*/
@media (min-width: 320px) and (max-width: 576px) {
    .headerLeft {
        transform: scale(0.9);
        justify-content: space-between;
    }

    .header {
        width: 100%;
    }

    .headerSign {
        padding: 15px 23px;
    }

    .headerDropdown {
        padding: 12px 19px;
    }
}

@media (min-width: 576px) {
    .headerLeft {
        transform: scale(1);
        justify-content: left;
    }

    .header {
        width: 85%;
    }
}

@media (max-width: 576px) {
    .headerHelp {
        display: none;
    }
}

@media (max-width: 768px) {
    .headerHelpTitle {
        display: none;
    }

    .headerHelp {
        margin-right: 10px;
    }

    .headerLeft {
        grid-gap: 5px;
    }
}