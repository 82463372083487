.footer {
    width: 100%;
    margin-top: 110px;
    background-color: rgba(var(--primary), 0.1);
}

.footer * {
    direction: rtl;
    text-align: right;
}

.footerContainer {
    padding: 30px;
    width: 85%;
    margin: 0 auto;
}

.footerConnect {
    padding: 60px 50px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-height: 450px;
    position: relative;
    top: -90px;
    background: #fff;
    box-shadow: 0 2px 17px 0 rgba(0, 0, 0, 0.11);
}

.footerConnectBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
}

.footerConnectBox:not(:last-child) {
    border-bottom: solid 2px #00000033;
    width: 100%;
    padding-bottom: 30px;
}

.footerConnectBox img {
    width: 65px;
    margin-bottom: 30px
}

.footerConnectBox span {
    color: #18224d;
}

.footerCopyright {
    font-size: 0.9rem;
    font-weight: 700;
    text-align: center;
    position: relative;
    top: -55px;
}


.footerAbout {
    font-weight: 600;
    font-size: 1.6rem;
    margin-top: 37px;
    text-align: right;
    color: rgb(var(--primary));
}

.footerAboutContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 45px;
}

.footerDesc {
    font-size: 1rem;
    line-height: 30px;
    font-weight: 400;
    font-stretch: normal;
    text-align: right;
    margin-top: 25px;
    color: #18224d;
}

.footerBrief {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;
}

.footerBriefChild {
    font-weight: 600;
    font-size: 1.2rem;
}


.footerLogo {
    margin-top: 30px;
    margin-bottom: 10px;
}

.footerImage {
    display: flex;
    justify-content: space-around;
    margin: 40px auto;
}

.footerImage img {
    transform: scale(0.8);
}

.footerMedia {
    text-align: center;
}

.footerSocial {
    display: flex;
    flex-direction: column;
}

.footerSocialIcon {
    width: 0.875em !important;
}

/* -------------------------------RESPONSIVE --------------------------------*/

@media (min-width: 300px) and (max-width: 769px) {
    .footerConnect {
        max-width: 400px;
        margin: 0 auto;
    }

    .footerAboutContainer {
        max-width: 700px;
        margin: 0 auto;
        padding: 0;
        margin-bottom: 40px;
    }

    .footerDesc {
        text-align: center;
    }

    .footerBrief li {
        text-align: center;
        font-size: 1rem !important;
        list-style: none;
    }

}

@media (min-width: 768px) {
    .footerSocialCol {
        margin-top: 30px;
    }

}

@media (max-width: 768px) {
    .footerContainer {
        width: 100%;
    }

    .footerDesc {
        text-align: justify;
        margin: 0 !important;
    }

    .footerAbout {
        margin: 0;
    }

    .footerBrief {
        display: flex;
        justify-content: right;
        padding: 0;
        align-items: flex-start;
        flex-direction: column;
        margin-top: 10px;
    }

    .footerBriefChild {
        font-weight: 600;
        font-size: 1.2rem;
    }

    .footerLogo {
        display: none;
    }

    .footerImage {
        margin: 0;
    }
}

@media (min-width: 990px) and (max-width: 1199px) {
    .footerSocialCol {
        display: none;
    }

    .footerAboutContainer {
        max-width: unset;
        padding: 0;
        margin: 0 auto;
        margin-bottom: auto;
    }
}

@media (min-width: 1200px) {
    .footerSocialCol {
        display: block;
    }

    .footerAboutContainer {
        height: 363px;
    }
}
