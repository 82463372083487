.dropdownMenu {
    border-radius: 30px !important;
    box-shadow: 0 2px 30px 0 rgba(0, 0, 0, .2) !important;
    margin-top: 5px !important;
    padding: 10px 0 !important;
    overflow: hidden;
    border: unset !important;
    min-width: 12rem !important;
}

.dropDownItem {
    margin: 10px auto;
    width: 85% !important;
    font-size: 0.85rem;
    font-weight: 500 !important;
    padding: 10px !important;
    position: relative;
    border-radius: 10px;
    transition: all 200ms linear;
    text-align: center !important;
}

.dropDownItem:hover {
    color: #ffffff !important;
    background-color: rgba(var(--primary), .7) !important;
}

.dropDownItem:not(:last-child):after {
    content: " ";
    left: 0;
    right: 0;
    bottom: -5px;
    height: 1px;
    background: #0000001c;
    position: absolute;
}

.togglerBtn {
    border-radius: 999px !important;
    vertical-align: middle;
    padding: 10px 20px !important;
}

.togglerBtn:after {
    margin-right: 10px;
}