.sideBarOverlay {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.4);
    z-index: -2;
    min-height: 100vh;
    opacity: 0;
    transition: all 300ms ease;
}

.sideBarOverlayShow {
    z-index: 999;
    opacity: 1;
}

.sideBar {
    position: absolute;
    top: 0;
    bottom: 0;
    min-height: 100vh;
    right: 0;
    width: 0;
    opacity: 0;
    background: white;
    z-index: 99;
    box-shadow: 0 3px 40px rgba(0, 0, 0, 0.6);
    padding: 50px 30px;
    overflow: auto;
    border-radius: 50px 0 0 50px;
    transition: all 100ms ease;
    display: flex;
    flex-direction: column;
    direction: rtl;
    text-align: right;
}

.sideBarShow {
    width: 20%;
    opacity: 1;
}

.sideBarMenu {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    list-style: none;
    padding: 0;
    height: 80vh;
    overflow: auto;
}

.sideBarMenuItem {
    margin: 10px 0;
    padding: 10px 20px;
    border-radius: 30px;
    transition: all 100ms ease;
}

.sideBarMenuItem.active span {
    color: #fff;
    font-size: 0.93rem;
}

.sideBarMenuItem img {
    transform: scale(0.9);
}

.sideBarMenuItem a {
    display: block;
}

.sideBarMenuItem:hover {
    background-color: rgb(var(--primary), 0.1);
}

.sideBarMenuItem:hover a {
    text-decoration: none;
}

.sideBarMenuItem span {
    color: #080809;
    margin-right: 20px;
    font-size: 0.9rem;
}

.sideBarTitle {
    display: flex;
}

.sideBarCloseIcon {
    width: 40px;
    height: 40px;
    padding: 10px;
    margin-right: auto;
    cursor: pointer;
    background-color: transparent;
    transition: all 300ms ease-in-out;
    border-radius: 999px;
}

.sideBarCloseIcon:hover {
    background-color: rgba(var(--primary), 0.1);
}

.sideBarButton {
    width: 100%;
    vertical-align: middle;
    display: inline-flex !important;
    justify-content: space-between;
    margin-top: auto;
}

.sideBarButton:hover .iconHolder {
    background-color: rgba(255, 255, 255, 0.2);
}

.sideBarButton:hover svg path {
    fill: white;
}

.iconHolder {
    width: 30px;
    height: 30px;
    display: inline-block;
    background-color: rgba(var(--primary), 0.6);
    border-radius: 999px;
    line-height: 30px;
}


@media only screen and (max-width: 1024px) and (min-width: 1300px) {
    .sideBarShow {
        width: 5%;
    }
}

@media only screen and (max-width: 768px) {
    .sideBarShow {
        width: 55%;
        opacity: 1;
    }
}

@media (min-width: 768px) and (max-width: 1200px) {
    .sideBarShow {
        width: 35%;
        opacity: 1;
    }
}

@media only screen and (max-width: 578px) {
    .sideBarShow {
        width: 75%;
        padding: 20px 5px;
        opacity: 1;
    }
}